<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item label="电站名称">
      <div v-if="operateType === 'single'">
        {{ currentRow.stationName }}
      </div>
      <el-scrollbar v-else-if="operateType === 'multiple'" :viewStyle="{ maxHeight: '200px' }">
        <div v-for="item in selectedRows" style="margin: 4px 0" :key="item.stationId">
          {{ item.stationName }}
        </div>
      </el-scrollbar>
    </ykc-form-item>
    <ykc-form-item label="是否收取占位费" prop="collectFlag">
      <ykc-radio :data="collectFlagData" v-model="ruleForm.collectFlag"></ykc-radio>
    </ykc-form-item>
    <template v-if="ruleForm.collectFlag === '1'">
      <ykc-form-item label="收费时间" prop="chargeTime">
        <div style="display: flex; justify-content: flex-start; align-items: center">
          <ykc-dropdown
            style="flex: 1"
            v-model="ruleForm.startTime"
            placeholder="开始时间 00:00"
            :data="startTimeData"></ykc-dropdown>
          <div style="width: 8px"></div>
          <ykc-dropdown
            style="flex: 1"
            v-model="ruleForm.endTime"
            placeholder="结束时间 24:00"
            :data="endTimeData"></ykc-dropdown>
        </div>
      </ykc-form-item>
      <ykc-form-item label="减免时长" prop="deductionTime">
        <ykc-input
          v-model="ruleForm.deductionTime"
          placeholder="请输入减免时长"
          @change="
            val =>
              (ruleForm.deductionTime = Math.round(
                Number.isNaN(Number(val || 1)) ? 1 : Number(val || 1)
              ))
          ">
          <template #append>分钟</template>
        </ykc-input>
      </ykc-form-item>
      <ykc-form-item label="占位费用" prop="occupyAmount">
        <ykc-input v-model="ruleForm.occupyAmount" placeholder="请输入占位费用">
          <template #append>元/分钟</template>
        </ykc-input>
      </ykc-form-item>
      <ykc-form-item label="费用上限" prop="occupyAmountLimit">
        <ykc-input v-model="ruleForm.occupyAmountLimit" placeholder="请输入费用上限">
          <template #append>元</template>
        </ykc-input>
      </ykc-form-item>
      <ykc-form-item label="客户选择" prop="orgList">
        <ykc-tree
          @hook:mounted="handleTreeMounted"
          ref="YkcTree"
          :data="treeData"
          @check-change="handleTreeCheckChange"></ykc-tree>
      </ykc-form-item>
    </template>
  </ykc-form>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import { occupyStrategy } from '@/service/apis';

  export default {
    props: ['selectedRows', 'currentRow', 'operateType', 'treeData'],
    data() {
      return {
        collectFlagData: [
          {
            id: '0',
            name: '否',
          },
          {
            id: '1',
            name: '是',
          },
        ],
        totalRangeData: Array.from({ length: 49 }).map((_, index) => {
          const id = `${index + 1}`;
          const hour = Math.floor(index / 2);
          const minute = (index % 2) * 30;
          const name = `${`${hour}`.padStart(2, '0')}:${`${minute}`.padStart(2, '0')}`;
          return {
            id,
            hour,
            minute,
            name,
          };
        }),
        ruleForm: {
          collectFlag: '1',
          startTime: '',
          endTime: '',
          chargeTime: '1',
          deductionTime: '',
          occupyAmount: '',
          occupyAmountLimit: '',
          orgList: [],
        },
        rules: {
          collectFlag: [{ required: true, message: '请选择是否收取占位费', trigger: 'blur' }],
          chargeTime: [
            { required: true, message: '请选择收费时间', trigger: 'blur' },
            {
              // eslint-disable-next-line
              validator: (rule, value, callback) => {
                if (!this.ruleForm.startTime) {
                  callback(new Error('请选择开始时间'));
                  return;
                }
                if (!this.ruleForm.endTime) {
                  callback(new Error('请选择结束时间'));
                  return;
                }
                callback();
              },
              trigger: 'blur',
            },
          ],
          deductionTime: [
            { required: true, message: '请输入减免时长', trigger: 'blur' },
            {
              validator: (_, val, cb) => {
                const value = Number(val || 0);
                if (Number.isNaN(value)) {
                  return cb(new Error('只能输入数字'));
                }
                if (value < 1 || value > 999) {
                  return cb(new Error('减免时长范围为1-999'));
                }
                return cb();
              },
              trigger: 'blur',
            },
          ],
          occupyAmount: [
            { required: true, message: '请输入占位费用', trigger: 'blur' },
            {
              validator: (_, val, cb) => {
                const value = Number(val || 0);
                if (Number.isNaN(value)) {
                  return cb(new Error('只能输入数字'));
                }
                if (val.replace(/.*\.(.*)$/, '$1').length > 2) {
                  cb(new Error('最多两位小数'));
                }
                if (value < 0.01 || value > 9.99) {
                  return cb(new Error('占位费用范围为0.01-9.99'));
                }
                if (value > this.ruleForm.occupyAmountLimit) {
                  return cb(new Error('分时占位费用不能大于费用上限'));
                }
                return cb();
              },
              trigger: 'blur',
            },
          ],
          occupyAmountLimit: [
            { required: true, message: '请输入费用上限', trigger: 'blur' },
            {
              validator: (_, val, cb) => {
                const value = Number(val || 0);
                if (Number.isNaN(value)) {
                  return cb(new Error('只能输入数字'));
                }
                if (val.replace(/.*\.(.*)$/, '$1').length > 2) {
                  cb(new Error('最多两位小数'));
                }
                if (value < 0.01 || value > 999.99) {
                  return cb(new Error('占位费用范围为0.01-999.99'));
                }
                return cb();
              },
              trigger: 'blur',
            },
          ],
          orgList: [{ required: true, message: '请选择客户', trigger: 'blur' }],
        },
      };
    },
    computed: {
      startTimeData() {
        const { endTime } = this.ruleForm;
        if (!endTime) {
          const data = [...this.totalRangeData];
          data.pop();
          return data;
        }
        return this.totalRangeData.filter(({ id }) => {
          id = Number(id);
          return id < endTime;
        });
      },
      endTimeData() {
        const { startTime } = this.ruleForm;
        if (!startTime) {
          const data = [...this.totalRangeData];
          data.shift();
          return data;
        }
        return this.totalRangeData.filter(({ id }) => {
          id = Number(id);
          return id > startTime;
        });
      },
    },
    created() {
      const setDefault = key => {
        this.ruleForm[key] = this.currentRow[key] || this.ruleForm[key];
      };

      if (this.operateType === 'single') {
        [
          'collectFlag',
          'startTime',
          'endTime',
          'deductionTime',
          'occupyAmount',
          'occupyAmountLimit',
        ].map(setDefault);
      }
      this.ruleForm.collectFlag = this.currentRow.status;
    },
    methods: {
      handleTreeMounted() {
        if (this.operateType === 'single') {
          if (this.currentRow.orgList && Array.isArray(this.currentRow.orgList)) {
            const ids = this.currentRow.orgList.map(String);
            this.ruleForm.orgList = ids;
            this.$refs.YkcTree.setCheckedKeys(ids);
          }
        }
      },
      handleTreeCheckChange() {
        this.ruleForm.orgList = this.$refs.YkcTree.getCheckedKeys(true) || [];
      },
      // eslint-disable-next-line
      submitForm(callback) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            if (this.operateType === 'single') {
              const data = {
                ...this.ruleForm,
                stationId: this.currentRow.stationId,
              };
              delete data.chargeTime;
              occupyStrategy.edit(data).then(res => {
                console.log('occupyStrategy single edit', res);
                callback();
              });
            } else if (this.operateType === 'multiple') {
              const data = {
                ...this.ruleForm,
                stationIds: this.selectedRows.map(({ stationId }) => stationId),
              };
              delete data.chargeTime;
              occupyStrategy.batchEdit(data).then(res => {
                console.log('occupyStrategy multiple edit', res);
                callback();
              });
            }
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  ._line {
    display: flex;
    & > * {
      margin-right: 5px;
    }
  }
</style>
